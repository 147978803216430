<template>
    <div>
        <b-modal :id="id" :title="title" ok-title="Upload & Save" cancel-title="cancel" @ok="uploadHandler" centered>
            <b-form-file size="lg" v-model="file" />
        </b-modal>
    </div>
</template>

<script>
import { uploadImage } from '@/services/AgentService';
import { BFormFile } from 'bootstrap-vue';
export default {
    name: "ImageUpload",
    components: {
        BFormFile
    },
    props: {
        id: String,
        title: String,
        name: String
    },
    data() {
        return {
            file: null
        }
    },
    methods: {
        async uploadHandler() {

            const imageUrl = await uploadImage(this.name, this.file);
            this.$emit('uploaded', imageUrl);
        }
    }
}
</script >
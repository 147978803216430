<template>
    <div>
        <b-row>
            <b-col>
                <BusinessPageDetails />
                <SeoDetails />
            </b-col>
            <b-col>
                <ImagesDetails />
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <TestimonialsDetails />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue";
import SeoDetails from './SeoDetails.vue';
import BusinessPageDetails from "./BusinessPageDetails.vue";
import TestimonialsDetails from "./TestimonialsDetails.vue";
import ImagesDetails from "./ImagesDetails.vue";
export default {
    name: "Page",
    components: {
        BCol,
        BRow,
        SeoDetails,
        BusinessPageDetails,
        TestimonialsDetails,
        ImagesDetails
    }
}
</script>
<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Images</b-card-title>
            </template>
            <b-card-body>
                <b-card>
                    <template #header>
                        <b-card-title>Primary Image</b-card-title>
                        <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.primaryImage-modal />
                    </template>
                    <b-card-body>
                        <div v-if="primaryImage">
                            <img :src="primaryImage" width="70%" height="70%" />
                        </div>
                        <div v-else>
                            <img src="/images/bg.jpeg" width="50%" height="50%" />
                        </div>
                    </b-card-body>
                </b-card>
                <b-card>
                    <template #header>
                        <b-card-title>Logo Image</b-card-title>
                        <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.logoImage-modal />
                    </template>
                    <b-card-body>
                        <div v-if="logoImage">
                            <img :src="logoImage" width="70%" height="70%" />
                        </div>
                        <div v-else>
                            <img src="/images/logo_biru.png" width="50%" height="50%" />
                        </div>
                    </b-card-body>
                </b-card>
                <b-card>
                    <template #header>
                        <b-card-title>Favicon Image</b-card-title>
                        <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.faviconImage-modal />
                    </template>
                    <b-card-body>
                        <div v-if="faviconImage">
                            <img :src="faviconImage" width="70%" height="70%" />
                        </div>
                        <div v-else>
                            <img src="/images/favicon.ico" />
                        </div>
                    </b-card-body>
                </b-card>
            </b-card-body>
        </b-card>

        <ImageUpload id="logoImage-modal" title="Logo Image Upload" name="logo" @uploaded="(e) => { this.logoImage = e }" />
        <ImageUpload id="faviconImage-modal" title="Favicon Image Upload" name="favicon" @uploaded="(e) => { this.faviconImage = e }" />
        <ImageUpload id="primaryImage-modal" title="Primary Image Upload" name="background" @uploaded="(e) => { this.primaryImage = e }" />
    </div>
</template>

<script>
import { getImages } from '@/services/AgentService';
import { BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BFormGroup, BFormInput, BModal, BRow } from 'bootstrap-vue';
import ImageUpload from './ImageUpload.vue';

export default {
    name: "ImagesDetails",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BModal,
        BFormGroup,
        BFormInput,
        BCol,
        BRow,
        ImageUpload
    },
    data() {
        return {
            primaryImage: null,
            logoImage: null,
            faviconImage: null
        }
    },
    async mounted() {
        const { logo, background, favicon } = await getImages();
        this.logoImage = logo;
        this.primaryImage = background;
        this.faviconImage = favicon;
    }
}
</script>
<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Testimonials</b-card-title>
            </template>
            <b-card-body>
                <b-row>
                    <b-col>
                        <b-card v-for="index in 3" :key="index">
                            <template #header>
                                <b-card-title>Testimonial ({{index}})</b-card-title>
                                <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal="`test${index-1}-modal`" />
                            </template>
                            <b-card-body>
                                <div class="col d-inline-flex">
                                    <blockquote class="blockquote mb-0">{{ testimonials[index-1].review }}
                                        <footer class="mt-1">
                                            <b-media>
                                                <template #aside>
                                                    <b-avatar size="100" :src="testimonials[index-1].image" class="cursor-pointer" v-b-modal="`testImage${index-1}-modal`" />
                                                </template>
                                                <div class="mt-1">
                                                    <h5 class="mb-0">{{ testimonials[index-1].name }}</h5>
                                                    <small class="text-muted mb-0">{{ testimonials[index-1].description }}</small>
                                                </div>
                                            </b-media>
                                        </footer>
                                    </blockquote>
                                </div>
                            </b-card-body>
                            <ImageUpload :id="`testImage${index-1}-modal`" title="Image Upload" :name="`test${index-1}`" @uploaded="(e)=>updateImageHandler(e,index-1)" />
                            <EditTestimonial :id="`test${index-1}-modal`" :title="`Testimonial ${index}`" :testimonial="testimonials[index-1]" :idx="`${index-1}`" @updated="updateTestimonialHandler" />
                        </b-card>
                    </b-col>

                    <b-col>
                        <b-card v-for="index in 3" :key="index">
                            <template #header>
                                <b-card-title>Testimonial ({{index+3}})</b-card-title>
                                <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal="`test${index-1+3}-modal`" />
                            </template>
                            <b-card-body>
                                <div class="col d-inline-flex">
                                    <blockquote class="blockquote mb-0">{{ testimonials[index-1+3].review }}
                                        <footer class="mt-1">
                                            <b-media>
                                                <template #aside>
                                                    <b-avatar size="100" :src="testimonials[index-1+3].image" />
                                                </template>
                                                <div class="mt-1">
                                                    <h5 class="mb-0">{{ testimonials[index-1+3].name }}</h5>
                                                    <small class="text-muted mb-0">{{ testimonials[index-1+3].description }}</small>
                                                </div>
                                            </b-media>
                                        </footer>
                                    </blockquote>
                                </div>
                            </b-card-body>
                            <ImageUpload :id="`testImage${index-1+3}-modal`" title="Image Upload" :name="`test${index-1+3}`" @uploaded="(e)=>updateImageHandler(e,index-1+3)" />
                            <EditTestimonial :id="`test${index-1+3}-modal`" :title="`Testimonial ${index+3}`" :testimonial="testimonials[index-1+3]" :idx="`${index-1+3}`" @updated="updateTestimonialHandler" />
                        </b-card>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { getImages, getTestimonial } from '@/services/AgentService';
import { BAvatar, BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BFormGroup, BFormInput, BMedia, BMediaAside, BMediaBody, BModal, BRow } from 'bootstrap-vue';
import EditTestimonial from './EditTestimonial.vue';
import ImageUpload from './ImageUpload.vue';

export default {
    name: "TestimonialsDetails",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BModal,
        BFormGroup,
        BFormInput,
        BCol,
        BRow,
        EditTestimonial,
        BAvatar,
        BMedia,
        BMediaAside,
        BMediaBody,
        ImageUpload
    },
    data() {
        return {
            testimonials: [
                {
                    name: "Abdul Rahim",
                    review: "Mengendalikan 4 cawangan perniagaan bukan hal mudah. HOI bantu saya mengendalikan kerja kerja memperbaharui insurans dan roadtax kenderaan saya. Saya sangat senang hati.",
                    description: "Petaling Jaya, Selangor",
                    image: "/images/faces/abdul.rahim.png",
                },
                {
                    name: "Fadhil Nayan",
                    review: "Dah tiga tahun saya guna HOI, semua 3 buah kenderaan saya dijaga oleh HOI, senang dan mudah!",
                    description: "Georgetown, Pulau Pinang",
                    image: "/images/faces/fadhil.nayan.png",
                },
                {
                    name: "Helmi Ramli",
                    review: "Waktu pandemic ni adalah sangat bahaya untuk beratur renew roadtax, saya settle dengan HOI. Senang hidup saya.",
                    description: "Taiping, Perak",
                    image: "/images/faces/helmi.ramli.png",
                },
                {
                    name: "Khairul Mudzaffar",
                    review: "Renewkan roadtax untuk bini saya di Terengganu sambil drive balik KL adalah sangat menyenangkan.",
                    description: "Batu Pahat, Johor",
                    image: "/images/faces/khairul.mudzaffar.png",
                },
                {
                    name: "Megat Syafique",
                    review: "Semua hal jadi automatic, saya dah tak perlu nak ingat lagi bila tarikh renewal kereta & motorsikal.",
                    description: "Shah Alam, Selangor",
                    image: "/images/faces/megat.syafique.png",
                },
                {
                    name: "Sufian Yusuf",
                    review: "Anak 5 orang, memang saya tak ada sebab nak beratur renew roadtax & insurance. Tak banyak masa yang ada. Ini pilihan terbaik.",
                    description: "Alor Setar, Kedah",
                    image: "/images/faces/sufian.yusof.png",
                }
            ]
        }
    },
    async mounted() {
        const testimonials = await getTestimonial();
        const images = await getImages();

        this.testimonials = testimonials.map((e, idx) => {
            return {
                name: e.name ?? this.testimonials[idx].name,
                review: e.review ?? this.testimonials[idx].review,
                description: e.description ?? this.testimonials[idx].description,
                image: images[`test${idx}`] ?? this.testimonials[idx].image
            }
        });

    },
    methods: {
        updateTestimonialHandler(e) {
            this.testimonials[e.idx] = {
                ...this.testimonials[e.idx],
                name: e.name,
                review: e.review,
                description: e.description
            }
            this.$forceUpdate();
        },
        updateImageHandler(e, idx) {
            this.testimonials[idx].image = e;
        }
    }
}
</script>
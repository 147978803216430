<template>
    <div>
        <b-modal :id="id" :title="title" ok-title="Upload & Save" cancel-title="cancel" @ok="updateTestimonial" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Review">
                        <b-form-textarea placeholder="Review" v-model="input.review" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Name">
                        <b-form-input placeholder="Name" v-model="input.name" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Title/Location">
                        <b-form-input placeholder="Title/Location" v-model="input.description" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { BCol, BFormFile, BFormGroup, BFormInput, BFormTextarea, BRow } from 'bootstrap-vue';
import { updateTestimonial } from '@/services/AgentService';
export default {
    name: "EditTestimonial",
    components: {
        BFormFile,
        BRow,
        BCol,
        BFormTextarea,
        BFormInput,
        BFormGroup
    },
    props: {
        id: String,
        title: String,
        testimonial: {
            review: String,
            name: String,
            description: String,
            image: String
        },
        idx: String
    },
    data() {
        return {
            input: {
                review: null,
                name: null,
                description: null
            }
        }
    },
    mounted() {
        this.input = { ...this.testimonial };
    },
    watch: {
        'testimonial.name'(newValue) {
            this.input.name = newValue
        },
        'testimonial.review'(newValue) {
            this.input.review = newValue
        },
        'testimonial.description'(newValue) {
            this.input.description = newValue
        }
    },
    methods: {
        async updateTestimonial() {
            await updateTestimonial(this.idx, this.input);
            this.$emit('updated', { ...this.input, image: this.testimonial.image, idx: this.idx });
        }
    },

}
</script>
<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Business Page Details</b-card-title>
                <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.businessPageDetails-modal />
            </template>
            <b-card-body>
                <h6 class="text-capitalize mb-75">
                    Business Link
                </h6>
                <b-card-text>
                    {{ page.businessLink || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Business Name
                </h6>
                <b-card-text>
                    {{ page.businessName || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Business Motto
                </h6>
                <b-card-text>
                    {{ page.businessMotto || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Business Catchphrase
                </h6>
                <b-card-text>
                    {{ page.businessCatchphrase || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Business Description
                </h6>
                <b-card-text>
                    {{ page.businessDescription || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Business Contact Person
                </h6>
                <b-card-text>
                    {{ page.businessContactPerson || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Business Email
                </h6>
                <b-card-text>
                    {{ page.businessEmail || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Google Location
                </h6>
                <b-card-text>
                    {{ page.googleLocation || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Primary Share Domain
                </h6>
                <b-card-text>
                    {{ page.primaryShareDomain || "(empty)" }}
                </b-card-text>
            </b-card-body>
        </b-card>

        <b-modal id="businessPageDetails-modal" title="Marketers Details" ok-title="Save" cancel-title="Discard" @ok="updatePage" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Business Link" label-for="businessLink">
                        <b-form-input v-model="input.businessLink" placeholder="Business Link" name="businessLink" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Business Name" label-for="businessName">
                        <b-form-input v-model="input.businessName" placeholder="Business Name" name="businessName" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Business Motto" label-for="businessMotto">
                        <b-form-input v-model="input.businessMotto" placeholder="Business Motto" name="businessMotto" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Business Catchphrase" label-for="businessCatchphrase">
                        <b-form-input v-model="input.businessCatchphrase" placeholder="Business Catchphrase" name="businessCatchphrase" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Business Description" label-for="businessDescription">
                        <b-form-input v-model="input.businessDescription" placeholder="Business Description" name="businessDescription" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Business Contact Person" label-for="businessContactPerson">
                        <b-form-input v-model="input.businessContactPerson" placeholder="Business Contact Person" name="businessContactPerson" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Business Email" label-for="businessEmail">
                        <b-form-input v-model="input.businessEmail" placeholder="Business Email" name="businessEmail" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Google Location" label-for="googleLocation">
                        <b-form-input v-model="input.googleLocation" placeholder="Google Location" name="googleLocation" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Primary Share Domain" label-for="primaryShareDomain">
                        <b-form-input v-model="input.primaryShareDomain" placeholder="Primary Share Domain" name="primaryShareDomain" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BFormGroup, BFormInput, BModal, BRow } from 'bootstrap-vue';
import { getPage, updatePage } from '@/services/AgentService';

export default {
    name: "BusinessPageDetails",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BModal,
        BFormGroup,
        BFormInput,
        BCol,
        BRow
    },
    data() {
        return {
            page: {
                businessLink: "",
                businessName: "",
                businessMotto: "",
                businessCatchphrase: "",
                businessDescription: "",
                businessContactPerson: "",
                businessEmail: "",
                googleLocation: "",
                primaryShareDomain: ""
            },
            input: {
                businessLink: "",
                businessName: "",
                businessMotto: "",
                businessCatchphrase: "",
                businessDescription: "",
                businessContactPerson: "",
                businessEmail: "",
                googleLocation: "",
                primaryShareDomain: ""
            }
        }
    },
    async mounted() {
        this.page = await getPage();
        this.input = { ...this.page };
    },
    methods: {
        async updatePage() {
            await updatePage(this.input);
            this.page = { ...this.input };
        }
    }
}
</script>

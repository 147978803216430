<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>SEO - Search Engine Optimization</b-card-title>
                <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.seoDetails-modal />
            </template>
            <b-card-body>
                <h6 class="text-capitalize mb-75">
                    Google Analytics ID
                </h6>
                <b-card-text>
                    {{ seo.googleAnalytics || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Google TagManager
                </h6>
                <b-card-text>
                    {{ seo.googleTagManager || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Google Webmaster Tools
                </h6>
                <b-card-text>
                    {{ seo.googleWebmasterTools || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Facebook Pixel
                </h6>
                <b-card-text>
                    {{ seo.facebookPixel || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Google Site Verification
                </h6>
                <b-card-text>
                    {{ seo.googleSiteVerification || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Facebook Site Verification
                </h6>
                <b-card-text>
                    {{ seo.facebookSiteVerification || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Facebook App ID
                </h6>
                <b-card-text>
                    {{ seo.facebookAppId || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    TikTok Ads ID
                </h6>
                <b-card-text>
                    {{ seo.tiktokAdsId || "(empty)" }}
                </b-card-text>
            </b-card-body>
        </b-card>

        <b-modal id="seoDetails-modal" title="Marketers Details" ok-title="Save" cancel-title="Discard" @ok="updateSeo" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Google Analytics" label-for="googleAnalytics">
                        <b-form-input v-model="input.googleAnalytics" placeholder="Google Analytics" name="googleAnalytics" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Google TagManager" label-for="googleTagManager">
                        <b-form-input v-model="input.googleTagManager" placeholder="Google TagManager" name="googleTagManager" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Google Webmaster Tools" label-for="googleWebmasterTools">
                        <b-form-input v-model="input.googleWebmasterTools" placeholder="Google Webmaster Tools" name="googleWebmasterTools" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Facebook Pixel" label-for="facebookPixel">
                        <b-form-input v-model="input.facebookPixel" placeholder="Facebook Pixel" name="facebookPixel" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Google Site Verification" label-for="googleSiteVerification">
                        <b-form-input v-model="input.googleSiteVerification" placeholder="Google Site Verification" name="googleSiteVerification" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Facebook Site Verification" label-for="facebookSiteVerification">
                        <b-form-input v-model="input.facebookSiteVerification" placeholder="Facebook Site Verification" name="facebookSiteVerification" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Facebook App ID" label-for="facebookAppId">
                        <b-form-input v-model="input.facebookAppId" placeholder="Facebook App ID" name="facebookAppId" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="TikTok Ads ID" label-for="tiktokAdsId">
                        <b-form-input v-model="input.tiktokAdsId" placeholder="TikTok Ads ID" name="tiktokAdsId" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { getSeo, updateSeo } from '@/services/AgentService';
import { BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BFormGroup, BFormInput, BModal, BRow } from 'bootstrap-vue';

export default {
    name: "SeoDetails",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BModal,
        BFormGroup,
        BFormInput,
        BCol,
        BRow
    },
    data() {
        return {
            seo: {
                googleAnalytics: "",
                googleTagManager: "",
                googleWebmasterTools: "",
                facebookPixel: "",
                facebookSiteVerification: "",
                googleSiteVerification: "",
                facebookAppId: "",
                tiktokAdsId: "",
            },
            input: {
                googleAnalytics: "",
                googleTagManager: "",
                googleWebmasterTools: "",
                facebookPixel: "",
                facebookSiteVerification: "",
                googleSiteVerification: "",
                facebookAppId: "",
                tiktokAdsId: "",
            }
        }
    },
    async mounted() {
        const seo = await getSeo();
        this.seo = { ...seo };
        this.input = this.seo;
    },
    methods: {
        async updateSeo() {
            await updateSeo(this.input);
            this.seo = this.input;
        }
    }
}
</script>